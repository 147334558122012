<template>
  <div id="segmentos" class="baloo_paaji_regular" >
    <br>
    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <h2 data-aos="fade-down" class="cor_red _font_2_5rem"><b>Conheça alguns segmentos</b></h2>
          <br>
          <pro-divisor></pro-divisor>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="2" v-for="(item, index) in lista" :key="'a'+index" class="cardComHover">
          <v-card height="100%" class="imagemComHover" data-aos="fade-up" >
            <v-img :src="item.src" contain height="70px" style="top: 5%"></v-img>
            <v-card-text>
              {{item.titulo}}
            </v-card-text>
          </v-card>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script async>
/* eslint-disable */
  import img_2 from '../../assets/img/icones/categorias/icone-hamburgueria.svg'
  import img_3 from '../../assets/img/icones/categorias/icone-pizzaria.svg'
  import img_4 from '../../assets/img/icones/categorias/icone-pastelaria.svg'
  import img_5 from '../../assets/img/icones/categorias/icone-restaurantes.svg'
  import img_6 from '../../assets/img/icones/categorias/icone-sorveteria.svg'
  import img_7 from '../../assets/img/icones/categorias/icone-esfiharia.svg'
  import img_8 from '../../assets/img/icones/categorias/icone-doguerias.svg'
  import img_9 from '../../assets/img/icones/categorias/icone-padaria.svg'
  import img_10 from '../../assets/img/icones/categorias/icone-gas.svg'
  import img_11 from '../../assets/img/icones/categorias/icone-bebidas.svg'
  import img_12 from '../../assets/img/icones/categorias/icone-doces.svg'

  export default {
    name: 'SecaoOndeEstamos',
    components: {
      VSlideGrupo: () => import('../../components/VSlideGrupo.vue'),
    },
    data: () => ({
      // Distribuidora de Gás, Distribuidoras de Bebidas, Doceiras
      lista: [
        {titulo:'Distribuidora de Gás', src:img_10},
        {titulo:'Distribuidora de Bebidas', src:img_11},
        {titulo:'Docerias', src:img_12},
        {titulo:'Hamburguerias', src:img_2},
        {titulo:'Pizzarias', src:img_3},/**/
        {titulo:'Pastelarias', src:img_4},/**/
        {titulo:'Restaurantes', src:img_5},/**/
        {titulo:'Sorveterias e Açaís', src:img_6},/**/
        {titulo:'Esfiharias', src:img_7},
        {titulo:'Doguerias', src:img_8},
        {titulo:'Padarias', src:img_9},/**/

      ],
    }),
    methods: {},
  }
</script>
